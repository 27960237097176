import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IFormInputChangePayload } from '../../abstract.components/abstract.form.component';
import { IW4FormData } from './tax-document-w4.reducer';
import { IW4SelectModels } from '../../../core/services/tax-documents/interfaces';

export const ActionTypes = {
  FORM_CONTROL_CHANGE: type('[Tax document w4 form] form control change'),
  FETCH_W4_SELECT_MODELS: type('[Tax document w4 form] fetch select models'),
  STORE_W4_SELECT_MODELS: type('[Tax document w4 form] store tax select models'),
  SUBMIT_TAX_DOCUMENT: type('[Tax document w4 form] submit form'),
  FETCH_TAX_INFO: type('[Tax document w4 view] fetch tax info'),
  STORE_TAX_INFO: type('[Tax document w4 view] store tax info'),
  TOGGLE_EXEMPTION: type('[Tax document w4 view] toggle exemption checkbox'),
};

export class TaxFormControlChange implements Action {
  public type = ActionTypes.FORM_CONTROL_CHANGE;

  constructor(public payload: IFormInputChangePayload) {}
}

export class FetchW4SelectModels implements Action {
  public type = ActionTypes.FETCH_W4_SELECT_MODELS;

  constructor(public payload?: any) {}
}

export class StoreW4Options implements Action {
  public type = ActionTypes.STORE_W4_SELECT_MODELS;

  constructor(public payload: IW4SelectModels | any) {}
}

export class SubmitTaxDocument implements Action {
  public type = ActionTypes.SUBMIT_TAX_DOCUMENT;

  constructor(public payload?: any) {}
}

export class FetchW4Info implements Action {
  public type = ActionTypes.FETCH_TAX_INFO;

  constructor(public payload?: any) {}
}

export class StoreW4Info implements Action {
  public type = ActionTypes.STORE_TAX_INFO;

  constructor(public payload: IW4FormData) {}
}

export class SetExemption implements Action {
  public type = ActionTypes.TOGGLE_EXEMPTION;

  constructor(public payload: boolean) {}
}

export type TypeAction =
  TaxFormControlChange |
  StoreW4Options |
  StoreW4Info |
  SetExemption |
  FetchW4SelectModels |
  SubmitTaxDocument |
  FetchW4Info;
