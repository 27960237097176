import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store';
import {
  ActionTypes,
  TypeAction
} from './tax-document-w4.actions';
import {
  createSelectModelFromStringArray
} from '../../../shared/components/select/models';
import { IW4SelectModels } from '../../../core/services/tax-documents/interfaces';

export interface IW4FormData {
  maritalStatus: string | null;
  numQualifyingChildren: string;
  numOtherDependents: string;
  additionalAmount: string;
  qualifyingChildrenTotal: number;
  qualifyingOtherDependentsTotal: number;
  totalDependentsAmount: number;
  overrideDependentAmount: string;
  otherIncome: string;
  deductions: string;
  multipleJobsOrSpouseWorks: boolean;
}

export interface IState {
  selectModels: IW4SelectModels;
  formData: IW4FormData;
  w4Data: IW4FormData;
  isExemptionAgreed: boolean;
}

const initialState: IState = {
  selectModels: {
    maritalStatus: []
  },
  formData: {
    maritalStatus: '',
    numQualifyingChildren: '',
    numOtherDependents: '',
    qualifyingChildrenTotal: 0,
    qualifyingOtherDependentsTotal: 0,
    totalDependentsAmount: 0,
    overrideDependentAmount: '',
    otherIncome: '',
    deductions: '',
    additionalAmount: '',
    multipleJobsOrSpouseWorks: false,
  },
  w4Data: {
    maritalStatus: '',
    numQualifyingChildren: '',
    numOtherDependents: '',
    qualifyingChildrenTotal: 0,
    qualifyingOtherDependentsTotal: 0,
    totalDependentsAmount: 0,
    overrideDependentAmount: '',
    otherIncome: '',
    deductions: '',
    additionalAmount: '',
    multipleJobsOrSpouseWorks: false,
  },
  isExemptionAgreed: true,
};

const QUALIFYING_CHILD_AMOUNT: number = 2000;
const QUALIFYING_DEPENDENT_AMOUNT: number = 500;

export function w4Reducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.FORM_CONTROL_CHANGE: {
      const { formData } = state;
      const { fieldId, value } = payload;
      const result = Object.assign({}, formData, { [fieldId]: value });

      return Object.assign({}, state, { formData: result });
    }

    case ActionTypes.STORE_W4_SELECT_MODELS: {
      return Object.assign({}, state, { selectModels: payload });
    }

    case ActionTypes.STORE_TAX_INFO: {
      return Object.assign({}, state, { w4Data: payload });
    }

    case ActionTypes.TOGGLE_EXEMPTION: {
      return Object.assign({}, state, { isExemptionAgreed: payload });
    }

    default: {
      return state;
    }
  }
}

const getFeatureState = ({ w4 }: IRootState): IState => w4;

export const getW4FormData = createSelector(
  getFeatureState,
  ({ formData }: IState): IW4FormData => ({
    ...formData,
    qualifyingChildrenTotal: +formData.numQualifyingChildren * QUALIFYING_CHILD_AMOUNT,
    qualifyingOtherDependentsTotal: +formData.numOtherDependents * QUALIFYING_DEPENDENT_AMOUNT,
    totalDependentsAmount: calculateDependentTotal(+formData.numQualifyingChildren, +formData.numOtherDependents),
  })
);

export const getW4SelectModels = createSelector(
  getFeatureState,
  ({ selectModels }: IState): any => {
    return Object.keys(selectModels).reduce((acc, key) => {
      acc[key] = createSelectModelFromStringArray(selectModels[key]);

      return acc;
    }, {});
  }
);

export const getW4Info = createSelector(
  getFeatureState,
  ({ w4Data }: IState): IW4FormData => w4Data
);

export const getIsExemptionAgreed = createSelector(
  getFeatureState,
  ({ isExemptionAgreed }: IState): boolean => isExemptionAgreed
);

export function calculateDependentTotal(numChildren: number, numOtherDependents: number): number {
  const childrenAmount = numChildren * QUALIFYING_CHILD_AMOUNT;
  const otherDependentAmount = numOtherDependents * QUALIFYING_DEPENDENT_AMOUNT;
  return childrenAmount + otherDependentAmount;
}
